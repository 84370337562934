<template>
    <div class="container mt-4">
        <h2 class="text-start mb-4 text-dark">SOCIALS</h2>
        <div class="bg-white rounded p-4 m-4">
            Check out our instagram at
            <a href="https://www.instagram.com/driftlist/">@driftlist</a>.
            <br /><br />
            Please send us any problems, complaints, criticisms, feedback,
            attaboys, threats of minor bodily harm, insider trading tips, or
            general correspondences at
            <a href="mailto:staff@driftlist.com">staff@driftlist.com</a>.
            &nbsp;&nbsp;Actually, please use Instagram, so we get
            traffic.&nbsp;&nbsp;kthx.
        </div>

        <div class="row mt-2 ml-auto mr-auto">
            <div v-for="(item, idx) in instagram_feed?.posts" v-bind:key="'instagram-feed-' + idx"
                class="col m-1 mb-3 text-center" style="min-width: 24%; max-width: 24%">
                <a :href="item?.mediaUrl">
                    <img :src="item?.mediaUrl" alt="Instagram image" 
                    loading="lazy"
                    class="rounded" width="350vh" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SocialsView",
    data() {
        return {
            instagram_feed: {},
        }
    },
    methods: {
        loadInstagramFeed: async function () {
            var url = "https://feeds.behold.so/t9GBfMbczmfifiYao6cF";
            var response = await fetch(url);
            var data = await response.json();
            this.instagram_feed = data;

        },
    },
    mounted: async function(){
        this.loadInstagramFeed();
    }
}
</script>

<style lang="css" scoped>
@media screen and (max-width: 768px) {
    h2{
        text-align: center !important;
    }
    .col{
        min-width: 100% !important;
        width: 100% !important;
        max-width: 100% !important;
    }
}
</style>
