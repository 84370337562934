<template>
  <div>
    <header>
      <nav class="first-nav">
        <a href="/"><img src="images/logo/logo-light-blue.png" alt="DriftList.com logo" id="menu-logo" /></a>
        <!-- Hamburger button for mobile -->
        <button class="hamburger-btn" @click="() => menuVisible = !menuVisible">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </nav>

      <!-- Mobile and Desktop Navigation -->
      <nav :class="['middle-nav', { 'mobile-visible': menuVisible }]">
        <router-link class="nav-link" to="/" @click="menuVisible = false">Home</router-link>
        <router-link class="nav-link" to="/events" @click="menuVisible = false">My&nbsp;Events</router-link>
        <router-link class="nav-link" to="/team" @click="menuVisible = false">My&nbsp;Team</router-link>

        <router-link class="nav-link" to="/socials" @click="menuVisible = false">Socials</router-link>
        <!-- TODO: Fix this-->
        <nav class="end-nav-mobile mt-2">
          <button @click="login()" v-if="!user" class="btn btn-primary">
            Login or Sign Up
          </button>
          <div class="dropdown" v-if="user">
            <a class="btn dropdown-toggle m-0 p-1 ps-2 pe-2 text-white" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img class="rounded-circle" height="30px" :src="user?.providerData[0]?.photoURL" />
            </a>
            <ul class="dropdown-menu text-dark" aria-labelledby="dropdownMenuButton1">
              <li class="text-center" v-if="isAdmin">
                <span class="text-success">ADMIN</span>
              </li>
              <li v-if="isAdmin">
                <hr class="m-0 mt-1 mb-1 p-0" />
              </li>
              <!-- li><a class="dropdown-item" href="#">User Settings</a></li> -->
              <li><a class="dropdown-item" href="#"><i>{{ user.email }}</i></a></li> 
              <li>
                <hr class="m-0 mt-1 mb-1 p-0" />
              </li>
              <li><a class="dropdown-item cursor-pointer" @click="logout()">Logout</a></li>
            </ul>
          </div>

      </nav>
        </nav>
        <nav class="end-nav">
          <button @click="login()" v-if="!user" class="btn btn-primary">
            Login or Sign Up
          </button>
          <div class="dropdown" v-if="user">
            <a class="btn dropdown-toggle m-0 p-1 ps-2 pe-2 text-white" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img class="rounded-circle" height="30px" :src="user?.providerData[0]?.photoURL" />
            </a>
            <ul class="dropdown-menu text-dark" aria-labelledby="dropdownMenuButton1">
              <li class="text-center" v-if="isAdmin">
                <span class="text-success">ADMIN</span>
              </li>
              <li v-if="isAdmin">
                <hr class="m-0 mt-1 mb-1 p-0" />
              </li>
              <!-- li><a class="dropdown-item" href="#">User Settings</a></li> -->
              <li><a class="dropdown-item" href="#"><i>{{ user.email }}</i></a></li> 
              <li>
                <hr class="m-0 mt-1 mb-1 p-0" />
              </li>
              <li><a class="dropdown-item cursor-pointer" @click="logout()">Logout</a></li>
            </ul>
          </div>

      </nav>
      
    </header>

    <router-view></router-view>
    <footer>
      Thanks to
      <a href="https://simplemaps.com/data/us-zips" nofollow>Simple Maps</a> for
      use of their geolocation dataset!
    </footer>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged  // Import the onAuthStateChanged observer
} from "firebase/auth";

import {
  getFirestore,
  collection,
  getDocs,
} from "firebase/firestore";

import { firebaseConfig } from "@/firebase_config.js";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);  // Initialize auth once for re-use throughout your component
export const db = getFirestore(app);

export default {
  name: "App",
  components: {},
  data() {
    return {
      loaded: false,
      results_loaded: false,
      user: null,
      isAdmin: false,
      menuVisible: false,
      toast: {},
    };
  },
  created() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        this.user = user;
        console.debug("User automatically logged in", user);
        this.checkIsAdmin();
      } else {
        // User is signed out.
        this.user = null;
      }
    });
  },
  methods: {
    showToast: function (text, title) {
      this.toast.title = title;
      this.toast.message = text;
      this.toast.show = true;
      this.$forceUpdate();
      setTimeout(() => {
        this.toast.show = false;
        this.$forceUpdate();
      }, 5000);
    },
    login: async function () {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        this.user = result.user;
        console.debug("Login successful", this.user);
      } catch (e) {
        this.showToast(`An error occurred when logging in: ${JSON.stringify(e)}`, "Error Logging in");
        console.error(e);
      }
    },
    logout: async function () {
      try {
        await signOut(auth);
        this.user = null;
        console.debug("User logged out");
        window.location.reload()
      } catch (e) {
        this.showToast(`An error occurred when logging out: ${JSON.stringify(e)}`, "Error Logging out");
        console.error(e);
      }
    },
    checkIsAdmin: async function () {
      const eventsCol = collection(db, "admins");
      try {
        await getDocs(eventsCol);
        this.isAdmin = true;
        console.debug("User is admin");
      } catch (e) {
        console.error(e);
        this.isAdmin = false;
      }
    },
  },
};
</script>


<style>
.nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;

}

.router-link-active {
  font-weight: bold;
  background-color: var(--bs-gray-dark);
}
</style>
<style>
/* Hamburger button styles */
.hamburger-btn {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 60px;
  height: 35px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.hamburger-btn span {
  width: 100%;
  height: 3px;
  background-color: #333;
  border-radius: 2px;
  transition: 0.3s;
}

.cursor-pointer{
  cursor: pointer;
}

nav.end-nav-mobile{
  display: none;
}
@media screen  and (max-width: 768px){
  
nav.end-nav{
  display: none !important;
}
nav.end-nav-mobile{
  display: block !important;
  width: 100% !important;
}
}

/* Responsive styling */
@media (max-width: 768px) {
  .hamburger-btn {
    display: flex;
  }

  #menu-logo {
    position: absolute;
    right: 25px;
  }



  .middle-nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    margin-top: 0.5rem;
    left: 0;
    width: 100% !important;
    background-color: var(--bs-gray);
    padding: 10px 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    height: unset;
  }

  .middle-nav.mobile-visible {
    display: flex;
  }

  .middle-nav.mobile-visible .end-nav {
    width: 100%;
  }

  .middle-nav.mobile-visible .end-nav button {
    width: 100%;
  }

  .end-nav {
    justify-content: center;
  }

  .nav-link {
    padding: 5px 10px;
    width: 100%;
    text-align: left;
    padding-bottom: 42px;
  }

  .row {
    overflow-x: scroll;
  }

  .col {
    width: 100%;
  }
}
</style>
