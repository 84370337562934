<template>
  <main>
    <!-- Toast -->
    <div
      :class="'toast ' + (toast.show ? 'show' : '')"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      style="display: none"
      ref="toast"
    >
      <div class="toast-header text-light bg-primary">
        <strong class="me-auto">{{ toast.title }}</strong>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
      <div class="toast-body text-light bg-primary">{{ toast.message }}</div>
    </div>
    <!-- Loading Spinner -->
    <div class="d-flex justify-content-center p-5" v-if="!loaded">
      <div
        class="spinner-border"
        role="status"
        style="margin-top: 20vh; height: 15vh; width: 15vh"
      ></div>
    </div>

    <!-- Confirm Modal -->
    <div
      class="modal fade modal"
      id="confirmModal"
      tabindex="-1"
      aria-labelledby="confirmModalLabel"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="blockedTimeLabel">
              Confirm Event Deletion?
            </h5>
          </div>

          <div class="modal-body">
            Are you sure you wish to delete this event:
            {{ selected_event?.name }}?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              id="confirmCancel"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-success"
              id="confirmOKButton"
              @click="remove_from_list(selected_event)"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Comments Modal -->
    <div
      class="modal fade modal-lg"
      id="commentsModal"
      tabindex="-1"
      aria-labelledby="commentsModalLabel"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="blockedTimeLabel">Comments</h5>
          </div>

          <div class="modal-body">
            <div
              class="row border rounded m-2 p-1 ms-0"
              v-for="(item, idx) in feedback[
                selected_event?.event_idx
              ]?.comments?.sort(
                (a, b) => a?.timestamp?.seconds - b?.timestamp?.seconds
              )"
              v-bind:key="'feedback-comment-' + idx"
            >
              <span class="fs-5">{{ item?.text }}</span>
              <br />
              <div class="row">
                <div class="col">
                  <i v-if="item?.owner_email != undefined">{{
                    item?.owner_email
                  }}</i>
                  <div v-else>
                    <b>Me ({{ user?.email }})</b><br />
                    <a
                      href="#"
                      @click="delete_comment(selected_event?.event_idx, item)"
                      >Delete comment</a
                    >
                  </div>
                </div>
                <div class="col text-end">
                  <i>
                    {{ formatDate(item?.timestamp?.toDate()) }}
                  </i>
                </div>
              </div>
            </div>
            <hr />
            <h6>Add a Comment</h6>
            <textarea
              class="form-control"
              v-model="add_comment_textarea"
            ></textarea>
            <br />
            <div class="text-start">
              <button
                class="btn btn-success"
                @click="add_comment(selected_event?.event_idx)"
              >
                + Add Comment
              </button>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              id="commentsCancel"
              @click="() => (selected_event = '')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Event Modal -->
    <div
      class="modal fade modal-lg"
      id="eventModal"
      tabindex="-1"
      aria-labelledby="eventModalLabel"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="blockedTimeLabel">
              {{ selected_event?.name }}
            </h5>
          </div>

          <div class="modal-body m-3">
            <div
              class="row"
              v-for="(event, idx) in event_keys"
              v-bind:key="'selected_event-' + idx"
            >
              <div class="col col-3 border p-2">{{ event }}</div>
              <div class="col border p-2" v-if="event == 'url'">
                <a :href="selected_event[event]">{{ selected_event[event] }}</a>
              </div>

              <div
                class="col border p-2"
                v-else-if="event == 'start' || event == 'end'"
              >
                {{
                  formatDate(
                    selected_event[event]?.date,
                    selected_event[event]?.timezone
                  )
                }}
                {{ selected_event[event]?.time }}
              </div>
              <div class="col border p-2" v-else-if="event == 'image'">
                <img :src="selected_event[event]" alt="Event Image" />
              </div>

              <div class="col border p-2" v-else>
                {{ selected_event[event] }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              id="eventCancel"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-success" id="eventOKButton">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Blocked Time Modal -->
    <div
      class="modal fade modal-lg"
      id="blockedTimeModal"
      tabindex="-1"
      aria-labelledby="blockedModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="blockedTimeLabel">
              Add/Edit Blocked Time
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row mt-2 mb-2">
              <div class="col">Start Date</div>
              <div class="col">
                <input
                  class="form-control"
                  type="date"
                  v-model="blocked_time.start"
                />
              </div>
            </div>
            <div class="row mt-2 mb-2">
              <div class="col">End Date</div>
              <div class="col">
                <input
                  class="form-control"
                  type="date"
                  v-model="blocked_time.end"
                />
              </div>
            </div>
            <div class="row mt-2 mb-2">
              <div class="col">
                Comments: <br />
                <textarea
                  class="form-control"
                  v-model="blocked_time.comment"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              id="blockedTimeCancel"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-success"
              id="blockdTimeOKButton"
              @click="create_edit_blocked_times"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- My Events -->
    <section id="events_list" ref="pdf" style="display: none">

      <div class="container bg-white rounded p-4 text-center" v-if="user?.uid == undefined">
            <h5>Please sign up or login to access your events list!<br /></h5>
            <br />
            <img src="/images/sample-mylist.jpg" alt="Sample view of My List" 
            style="min-width:300px; width:50%;"
            />
      </div>
      <div class="container" v-else>
        <div class="row" v-if="!pdf_view">
          <div class="col">
            <h2 class="text-start mb-4 text-dark">MY DRIFT LIST</h2>
          </div>
          <div class="col text-end">
            <button class="btn btn-success" @click="sendTestEmail()">
              <i class="bi bi-envelope-at-fill"></i>&nbsp;Email Schedule
            </button>
            <button @click="saveAsPDF()" class="btn btn-success ms-3">
              <i class="bi bi-floppy-fill"></i>&nbsp; Save as PDF
            </button>
          </div>
        </div>
        <div class="row" v-if="!pdf_view">
          <div class="col text-start">
            <button
              class="btn btn-dark"
              data-bs-toggle="modal"
              data-bs-target="#blockedTimeModal"
            >
              +&nbsp;Add Blocked Time
            </button>
          </div>
        </div>
        <br />

        <!-- Show pending team invites -->
        <div
          v-for="(item, pending_idx) in pending_teams"
          v-bind:key="'pending-teams' + pending_idx"
        >
          <div class="rounded bg-info p-3" v-if="item[0] != user.email">
            <div>
            You have a pending invite from {{ item[0] }} to join their team. Do you
            want to accept?
            </div>
            <div class="text-end">
              <button class="btn btn-light"
              @click="deny_team_invite(item[1])"
              >Deny Request</button>
              &nbsp;&nbsp;
              <button class="btn btn-dark"
              @click="accept_team_invite(item[1])"
              >Accept Request</button>
            </div>
          </div>
        </div>

        <div class="rounded mb-2 mt-2 bg-light p-3"
        v-if="!pdf_view">
          <h5>Legend</h5>
          <div class="row">
            <div class="col">
              <div class="row">
                <div
                  class="col col-5"
                  v-for="(item, idx) in computed_emails"
                  v-bind:key="'computed-emails-' + idx"
                >
                  <div class="bg-white p-2 rounded border">
                    <img
                      :src="getGravatarUrl(item, 30)"
                      alt="Team member icon"
                    />
                    &nbsp;{{ item.split("@")[0] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col text-end">
              <div>
                <button
                  :class="
                    'btn ' +
                    (show_only_my_list == false
                      ? 'btn-primary'
                      : 'btn-outline-primary')
                  "
                  @click="show_only_my_list = !show_only_my_list"
                >
                  All Lists
                </button>
                <button
                  @click="show_only_my_list = !show_only_my_list"
                  :class="
                    'ms-3 btn ' +
                    (show_only_my_list == true
                      ? 'btn-primary'
                      : 'btn-outline-primary')
                  "
                >
                  Only My List
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="rounded p-2 bg-white mb-2">
          <div class="d-flex justify-content-center p-5" v-if="results_loading">
            <div
              class="spinner-border"
              role="status"
              style="height: 5vh; width: 5vh"
            ></div>
          </div>

          <table class="table border-light border mb-0" v-else>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>Start Date</th>
                <th>
                  Organizer
                  <i
                    :class="sortingFormatter('organizer')"
                    @click="sortingAction('organizer')"
                  ></i>
                </th>
                <th>
                  Name
                  <i
                    :class="sortingFormatter('name')"
                    @click="sortingAction('name')"
                  ></i>
                </th>
                <th>
                  Type
                  <i
                    :class="sortingFormatter('type')"
                    @click="sortingAction('type')"
                  ></i>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(event, event_idx) in sorted_raw"
                v-bind:key="'raw-events' + event_idx"
              >
                <td class="border border-light">
                  <button
                    class="btn btn-link"
                    data-bs-toggle="modal"
                    data-bs-target="#confirmModal"
                    v-if="event.owner_email == user.email"
                    @click="selected_event = event"
                  >
                    <i class="bi bi-x-circle text-secondary"></i>
                  </button>
                </td>
                <td class="border border-light">
                  <img :src="getGravatarUrl(event.owner_email, 25)" />
                </td>
                <td class="border border-light">
                  <span :class="event.collision == true ? 'text-danger' : ''">
                    {{ formatDate(event.start?.date) }}
                    <span
                      v-if="event.start?.date != event.end?.date"
                      :class="event.collision == true ? 'text-danger' : ''"
                    >
                      - {{ formatDate(event.end?.date, 1) }}</span
                    ></span
                  >
                </td>

                <td class="border border-light">{{ event.organizer }}</td>
                <td class="border border-light">
                  <a
                    href="#"
                    @click="selected_event = event"
                    data-bs-toggle="modal"
                    data-bs-target="#eventModal"
                  >
                    {{ event.name }}
                  </a>
                </td>
                <td
                  :class="
                    'border border-light ' +
                    (event.type == 'Blocked' ? 'bg-dark text-light' : '')
                  "
                >
                  {{ event.type }}
                </td>
                <td class="border border-light">
                  <div v-if="event.type != 'Blocked'">
                    <span
                      :class="
                        'cursor-pointer bi text-success fs-4 bi-hand-thumbs-up' +
                        (feedback[event?.event_idx]?.upvotes?.indexOf(
                          user?.email
                        ) >= 0
                          ? '-fill'
                          : '')
                      "
                      @click="change_vote(event?.event_idx, 'up')"
                    >
                      {{
                        feedback[event?.event_idx]?.upvotes?.length
                      }}&nbsp; </span
                    >&nbsp;
                    <span
                      :class="
                        'cursor-pointer bi fs-4 bi-hand-thumbs-down' +
                        (feedback[event?.event_idx]?.downvotes?.indexOf(
                          user?.email
                        ) >= 0
                          ? '-fill'
                          : '')
                      "
                      @click="change_vote(event?.event_idx, 'down')"
                    >
                      {{
                        feedback[event?.event_idx]?.downvotes?.length
                      }}&nbsp; </span
                    >&nbsp;
                    <span
                      class="cursor-pointer bi bi-chat fs-4"
                      data-bs-toggle="modal"
                      data-bs-target="#commentsModal"
                      @click="
                        () => {
                          selected_event = event;
                          add_comment_textarea = '';
                        }
                      "
                    >
                      {{ feedback[event?.event_idx]?.comments?.length }}&nbsp;
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import CryptoJS from "crypto-js";

// Add Firebase products that you want to use
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  query,
  where,
  Timestamp,
} from "firebase/firestore";

import { httpsCallable, getFunctions } from "firebase/functions";

import { firebaseConfig } from "@/firebase_config.js";

import html2pdf from "html2pdf.js";
import { initializeApp } from "firebase/app";

// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const functions = getFunctions(app, "us-central1");
export default {
  name: "EventsPage",
  data() {
    return {
      user: null,
      loaded: false,
      results_loading: false,
      my_events: {},
      all_events: [],
      selected_event: {},
      raw_events: [],
      owner_emails: new Set(),
      colors: ["primary", "secondary", "success", "warning", "info", "dark"],
      toast: {
        show: false,
        title: "Test title",
        message: "Test Message",
      },
      blocked_time: {
        start: null,
        end: null,
        comment: "",
      },
      months: {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      },

      event_keys: [
        "name",
        "organizer",
        "type",
        "url",
        "description",
        "venue_name",
        "street_address",
        "start",
        "end",
        "image",
      ],
      sorting_key: "",
      sorting_direction: 1,
      show_only_my_list: false,

      feedback: {},
      add_comment_textarea: "",
      pending_teams: [],
      pdf_view: false,
    };
  },
  methods: {
    showToast: function (text, title) {
      this.toast.title = title;
      this.toast.message = text;
      this.toast.show = true;
      this.$forceUpdate();
      setTimeout(() => {
        this.toast.show = false;
        this.$forceUpdate();
      }, 5000);
    },
    login: async function () {
      console.debug("Login !");
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      try {
        const result = await signInWithPopup(auth, provider);
        this.user = result.user;
        console.debug(`User: ${this.user}`);
        console.debug(this.user);
      } catch (e) {
        this.showToast(
          `An error occurred when logging in: ${JSON.stringify(e)}`,
          "Error Logging in"
        );
        console.error(e);
      }
      this.checkIsAdmin();
    },
    logout: async function () {
      const auth = getAuth();
      try {
        await signOut(auth);
        this.user = null;
        console.debug("User logged out");
      } catch (e) {
        this.showToast(
          `An error occurred when logging out: ${JSON.stringify(e)}`,
          "Error Logging out"
        );
        console.error(e);
      }
      this.checkIsAdmin();
    },

    saveAsPDF: async function () {
      this.pdf_view = true
      await this.$forceUpdate()
      const doc = this.$refs.pdf;
      console.log("starting pdf download...");
      html2pdf(doc).then((x) => {
        console.log(x);
        console.log("Done");
        this.pdf_view = false
        this.$forceUpdate()
      }).catch(e=>{
        console.error(e)
        this.pdf_view = false
        this.$forceUpdate()
      });
    },

    sendTestEmail: async function () {
      // Get a reference to the sendTestEmail Callable Function
      const sendPDFEmail = httpsCallable(functions, "sendPDFEmail");

      this.pdf_view = true
      await this.$forceUpdate()
      const doc = this.$refs.pdf;
      const pdf = btoa(await html2pdf().from(doc).outputPdf());
      this.pdf_view = false
      try {
        // Call the function with any necessary data
        await sendPDFEmail({
          data: pdf,
        });
        this.showToast(
          "PDF has been succesfully sent to your email.  Please check your spam folder if it does not show up.",
          "Email Sent!"
        );
      } catch (error) {
        this.showToast(
          "An error occurred when sending email with PDF.  Please send us a message at staff@driftlist.com and we will investigate.",
          "Email Failed to Send"
        );
        console.error("Error calling sendTestEmail:", error);
      }
    },

    get_event: async function (event_id) {
      // Gets an event based on ID
      var found_doc = await getDoc(doc(db, "events", event_id));
      return found_doc.data();
    },
    formatDate: function (date, timezone) {
      try {
        var a = new Date(date);
        if (timezone != undefined) {
          a.timezone = timezone;
        }

        const str = a.toISOString().split("T")[0].split("-");
        return `${this.months[str[1]]} ${str[2]}, ${str[0]}`;
        //return a.toISOString().split("T")[0];
      } catch (e) {
        console.log(e);
        return "";
      }
    },

    /*
    removeEventFromList: async function (event) {
      var list_doc = doc(db, "list", this.user.uid);
      if (this.user.uid == undefined) {
        this.showToast(`Cannot remove an event from the list when logged out.`, "Please login")
        console.error("Cannot add event to list when not logged in");
        return false;
      }
      let data;

      try {
        list_doc = await getDoc(list_doc);
        data = list_doc.data();
      } catch (e) {
        return false; // If it doesn't exist, we can't remove it
      }
      // Add Event to list
      delete data.events[event.id];

      await setDoc(doc(db, "list", this.user.uid), data);
      this.listAllMyEvents();
    },*/
    create_edit_blocked_times: async function () {
      if (!this.user?.uid) {
        return false;
      }
      var found_doc = await getDoc(doc(db, "list", this.user.uid));
      console.log(this.user);
      console.log(found_doc);

      found_doc = found_doc.data();

      var item = {
        ...this.blocked_time,
        email: this.user.email,
      };

      if (found_doc.blocked_times == undefined) {
        found_doc.blocked_times = [];
      }

      if (this.blocked_time.idx == undefined) {
        found_doc.blocked_times.push(item);
      } else {
        found_doc.blocked_times[item.idx] = item;
        delete found_doc.blocked_times[item.idx].idx;
      }

      await setDoc(doc(db, "list", this.user.uid), found_doc);
      this.raw_events = [];
      this.list_all_lists();
      document.getElementById("blockedTimeCancel").click();
    },

    remove_from_list: async function (event) {
      var found = await getDoc(doc(db, "list", this.user.uid));
      found = found.data();
      if (event.event_idx != undefined) {
        // Delete the event idx
        delete found.events[event.event_idx];
      } else if (event.idx != undefined) {
        // Delete the blocked time idx
        found.blocked_times.splice(event.idx, 1);
      }
      await setDoc(doc(db, "list", this.user.uid), found);
      this.selected_event = {};
      this.raw_events = [];
      this.list_all_lists();
      document.getElementById("confirmCancel").click();
    },
    change_vote: async function (event_idx, type) {
      // Changes a vote - either upvote or downvote
      var found = await getDoc(doc(db, "list", this.user.uid));
      found = found.data();
      // Check if the event exists in the list.  If not, create it.
      if (found.feedback == undefined) {
        found.feedback = {};
      }

      if (found?.feedback[event_idx] == undefined) {
        found.feedback[event_idx] = {
          comments: [],
          downvote: 0,
          upvote: 0,
        };
      }

      if (this.feedback[event_idx] == undefined) {
        this.feedback[event_idx] = {
          comments: [],
          upvotes: [],
          downvotes: [],
        };
      }

      this.feedback[event_idx].upvotes = this.feedback[
        event_idx
      ].upvotes.filter((x) => x != this.user.email);
      this.feedback[event_idx].downvotes = this.feedback[
        event_idx
      ].downvotes.filter((x) => x != this.user.email);

      // Change vote
      if (type == "up") {
        found.feedback[event_idx].upvote = 1;
        found.feedback[event_idx].downvote = 0;
        this.feedback[event_idx].upvotes.push(this.user.email);
      }
      if (type == "down") {
        found.feedback[event_idx].upvote = 0;
        found.feedback[event_idx].downvote = 1;
        this.feedback[event_idx].downvotes.push(this.user.email);
      }
      // Save
      try {
        await setDoc(doc(db, "list", this.user.uid), found);
      } catch (e) {
        this.showToast(
          `Vote is likely not saved.  Error: ${e}`,
          "Error in Voting"
        );
      }
      console.log(this.feedback);
    },
    add_comment: async function (event_idx) {
      // Adds a comment
      var found = await getDoc(doc(db, "list", this.user.uid));
      found = found.data();
      var comment = {
        text: this.add_comment_textarea,
        timestamp: Timestamp.now(),
      };
      // Checks if the event exists in the list.  If not, create it.
      if (!found) {
        found = {
          feedback: {},
        };
      }
      if (found.feedback[event_idx] == undefined) {
        found.feedback[event_idx] = {
          downvote: 0,
          upvote: 0,
          comments: [comment],
        };
      } else {
        found.feedback[event_idx].comments.push(comment);
      }
      // Save
      await setDoc(doc(db, "list", this.user.uid), found);

      this.feedback[event_idx].comments.push(comment);
      this.add_comment_textarea = "";
      this.raw_events = [];
      this.list_all_lists();
    },

    delete_comment: async function (event_idx, comment) {
      var found = await getDoc(doc(db, "list", this.user.uid));
      found = found.data();
      // Checks if the event exists in the list.  If not, create it.
      if (!found) {
        return false;
      }
      if (
        found.feedback[event_idx] == undefined ||
        found.feedback[event_idx].comments == undefined
      ) {
        return false;
      }

      found.feedback[event_idx].comments = found.feedback[
        event_idx
      ].comments.filter((x) => x.text != comment.text);

      // Save
      await setDoc(doc(db, "list", this.user.uid), found);

      this.raw_events = [];
      this.list_all_lists();
    },

    getEmailHash: function (email) {
      // Normalize the email address
      const normalizedEmail = email.trim().toLowerCase();

      // Hash the email address
      const hash = CryptoJS.MD5(normalizedEmail);

      return hash.toString(CryptoJS.enc.Hex);
    },

    getGravatarUrl: function (email, size = 100, def = "robohash") {
      if (email == undefined) {
        return "";
      }
      const hash = this.getEmailHash(email);
      return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=${def}`;
    },
    accept_team_invite: async function(uid){
      // Find the team with the owner email
      var docs = await getDoc(doc(db, "list", uid))
      var temp = docs.data()
      temp.pending_members = temp?.pending_members?.filter(x=>x != this.user.email)
      setDoc(doc(db, "list", uid), temp)
      this.pending_teams = this.pending_teams.filter(x=>x[1] != uid)
      await this.list_all_lists(true)
      this.$forceUpdate()
    },
    deny_team_invite: async function(uid){
      var docs = await getDoc(doc(db, "list", uid))
      var temp = docs.data()
      temp.pending_members = temp?.pending_members?.filter(x=>x != this.user.email)
      temp.can_access = temp?.can_access?.filter(x=>x != this.user.email)
      setDoc(doc(db, "list", uid), temp)
      this.pending_teams = this.pending_teams.filter(x=>x[1] != uid)
      await this.list_all_lists(true)
      this.$forceUpdate()
    },
    list_all_lists: async function (silent) {
      // Load all lists that we have access to
      console.log("SILENT:");
      console.log(silent);
      if (silent != true) {
        this.results_loading = true;
      }
      var q = query(
        collection(db, "list"),
        where("can_access", "array-contains", this.user.email)
      );
      var allDocs = await getDocs(q);

      this.all_events = {};
      this.feedback = [];
      console.log(allDocs);
      allDocs.forEach(async (doc) => {
        
        var temp = doc.data();

        console.log("LIST DATA:");
        console.log(temp);

        if (temp?.pending_members?.indexOf(this.user.email) !== -1) {
          this.pending_teams.push([temp?.owner_email, doc?.id]);
        } else {
          Object.keys(temp.events).forEach(async (doc_id) => {
            try {
              var event = await this.get_event(doc_id);

              /*
            var name = temp.events[doc_id];
            if (temp.owner_email != this.user.email) {
              name = `${name} (${temp.owner_email})`;
            }
            */

              // Check if we're pending

              event.owner_email = temp.owner_email;
              event.event_idx = doc_id;

              this.raw_events.push(event);
              // Loops from start to end dates
              /*
            var start_date = new Date(event?.start?.date);
            var end_date = new Date(event?.end?.date);

            if (start_date > end_date) {
              this.showToast(
                `An Event has incorrect start and end dates.  This event cannot be displayed.`,
                "Invalid Event Dates"
              );
              console.error("Invalid event start and end dates");
            } else {
              while (start_date <= end_date) {
                if (!(start_date in this.all_events)) {
                  this.all_events[start_date] = [];
                }
                this.all_events[start_date].push({
                  owner_email: temp.owner_email,
                  uid: doc_id,
                  name: name,
                  start_date: event["start_date"],
                  start: event["start"],
                  organizer: event["organizer"],
                  nearest_city: event?.nearest_city,
                  type: event["type"],
                });

                start_date.setDate(start_date.getDate() + 1);
              }
            }
            start_date = null;
            end_date = null;
            */
              this.owner_emails.add(temp.owner_email);
            } catch (e) {
              this.showToast(`Warning: ${e}`, "Warning in Events");
            }
          });

          // Blocked times
          if (temp.blocked_times != undefined) {
            temp.blocked_times.forEach((item, idx) => {
              // item.start, item.end
              //var start = new Date(item?.start);
              //var end = new Date(item?.end);

              // TODO: RAW EVENTS
              this.raw_events.push({
                ...item,
                start: { date: item.start },
                end: { date: item.end },
                idx: idx,
                owner_email: item?.email,
                organizer: item?.email,
                name: item?.comment,
                type: "Blocked",
              });

              //end.setDate(end.getDate() + 1);

              /*
            while (start <= end) {
              if (!(start in this.all_events)) {
                this.all_events[start] = [];
              }

              this.all_events[start].push({
                blocked: true,
                organizer: item?.email?.split("@")[0],
                nearest_city: "-",
                type: "Blocked",
                owner_email: item.email,
                name: item.comment,
                start_date: item.start,
                start: item.start,
                end: item.end,
                comment: item.comment,
              });

              start.setDate(start.getDate() + 1);
            } */
            });
          }

          // Feedback
          if (temp.feedback != undefined) {
            Object.keys(temp.feedback).forEach((f) => {
              if (this.feedback[f] == undefined) {
                this.feedback[f] = {
                  comments: null,
                  upvotes: [],
                  downvotes: [],
                };
                this.feedback[f].comments = temp.feedback[f].comments;
              } else {
                // Need to aggregate comments and up/down votes
                temp.feedback[f].comments.forEach((x) => {
                  x.owner_email = temp.owner_email;
                });
                this.feedback[f].comments = [
                  ...this.feedback[f].comments,
                  ...temp.feedback[f].comments,
                ];
              }
              if (temp.feedback[f].upvote == 1) {
                this.feedback[f].upvotes.push(temp.owner_email);
              }
              if (temp.feedback[f].downvote == 1) {
                this.feedback[f].downvotes.push(temp.owner_email);
              }
            });
          }
        }
      });
      if (silent != true) {
        this.results_loading = false;
      }
    },

    findCollisions: function (arr) {
      let r; // Right pointer - this is the ending date
      let r_idx; // Right Index

      for (var i = 0; i < arr.length; i++) {
        var l = new Date(arr[i].start.date).getTime();
        var temp_end = new Date(arr[i].end.date).getTime();

        if (!r) {
          // Initial set
          r = temp_end;
          r_idx = i;
        } else {
          if (l <= r) {
            // Since this is a sorted list, if the current start date is less than the preivous end date
            // then we have a collision
            arr[i].collision = true;
            arr[r_idx].collision = true; // Set the collider as well
          }

          // Update the r value if our end date is greater than it
          if (temp_end > r) {
            r = temp_end;
            r_idx = i;
          }
        }
      }
      return arr;
    },
    sortingFormatter: function (key) {
      var output = "bi cursor-pointer ";
      if (this.sorting_direction == -1) {
        output += "bi-caret-down";
      } else {
        output += "bi-caret-up";
      }
      if (this.sorting_key == key) {
        output += "-fill text-dark";
      } else {
        output += " text-light-grey";
      }
      return output;
    },
    sortingAction: function (key) {
      if (this.sorting_key == key) {
        this.sorting_direction = this.sorting_direction * -1;
      } else {
        this.sorting_key = key;
      }
      this.$forceUpdate();
    },
  },
  computed: {
    sorted_events_keys: function () {
      var temp = Object.keys(this.all_events);
      temp.sort((a, b) => new Date(a) - new Date(b));
      return temp;
    },
    sorted_raw: function () {
      //var temp = this.raw_events.slice(0)
      var temp = [...this.raw_events];
      temp.sort((a, b) => {
        if (this.sorting_key == "") {
          var a_start_date = new Date(a.start.date).getTime();
          var b_start_date = new Date(b.start.date).getTime();
          return isNaN(a_start_date - b_start_date)
            ? 0
            : a_start_date - b_start_date;
        } else {
          // Sort by the selected key
          var a_temp = a[this.sorting_key];
          if (a_temp == undefined) {
            a_temp = "";
          }
          var b_temp = b[this.sorting_key];
          if (b_temp == undefined) {
            b_temp = "";
          }
          a_temp = a_temp.toLowerCase();
          b_temp = b_temp.toLowerCase();

          if (a_temp < b_temp) {
            return -1 * this.sorting_direction;
          } else if (a_temp > b_temp) {
            return this.sorting_direction;
          } else {
            // Sort by the date
            a_start_date = new Date(a.start.date).getTime();
            b_start_date = new Date(b.start.date).getTime();
            return isNaN(a_start_date - b_start_date)
              ? 0
              : a_start_date - b_start_date;
          }
        }
      });
      temp = this.findCollisions(temp);
      if (this.show_only_my_list) {
        temp = temp.filter((x) => x.owner_email == this.user.email);
      }
      return temp;
    },
    computed_emails: function () {
      return Array.from(new Set(this.sorted_raw.map((x) => x?.owner_email)));
    },
  },
  mounted: async function () {
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {})
      .catch((e) => {
        // TODO: Toast
        console.error(e);
      });

    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        console.debug("USER");
        console.debug(this.user);

        this.list_all_lists();
      } else {
        console.debug("Not logged in");
      }
    });
    // Turn on all the refs
    Object.values(this.$refs).forEach((ref) => {
      ref.style.display = "";
    });
    this.loaded = true;
    this.$forceUpdate();
  },
};
</script>
<style scoped>
@media screen and (max-width: 1000px){
  .col-5{
    width: unset !important;
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width: 768px) {
  .ms-3{
    margin-left: unset !important;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead {
    display: block;
  }
  td {
    display: block;
    width: 100%;
    text-align: center;
    background-color: var(--bs-gray-300);
    border-radius: var(--bs-border-radius) !important;
    border-collapse: collapse;
  }
  th {
    display: block;
    width: 100%;
    background-color: var(--bs-gray-300);
    text-align: center;
  }
  tr {
    display: block;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-bottom: 1px solid var(--bs-gray-600);
    padding-bottom: 1rem;
    border-collapse: collapse;
  }
  .container {
    width: 100% !important;
  }
  .col {
    width: 100% !important;
  }
  .row {
    display: block;
  }
  button {
    width: 100%;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}
</style>